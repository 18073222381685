//#region Imports

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogChangeUserPasswordComponent } from './dialog-change-user-password.component';
import { DialogChangeUserPasswordModule } from './dialog-change-user-password.module';

//#endregion

@Injectable({
  providedIn: DialogChangeUserPasswordModule,
})
export class DialogChangeUserPasswordService {

  //#region Constructor

  constructor(
    private dialog: MatDialog,
  ) { }

  //#endregion

  //#region Private Properties

  private dialogRef: MatDialogRef<DialogChangeUserPasswordComponent> | undefined;

  //#endregion

  //#region Public Methods

  public openDialog(): MatDialogRef<DialogChangeUserPasswordComponent> | undefined {
    this.dialogRef = this.dialog.open(DialogChangeUserPasswordComponent, {
      disableClose: true,
    });

    return this.dialogRef;
  }

  //#endregion

}
