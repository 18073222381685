import { VersionName } from './versionName';

export const environment = {
  production: false,
  qas: false,
  version: VersionName,
  api_endpoint: 'https://3cscr7l3ve.execute-api.us-east-1.amazonaws.com/dev',
  config: {
    redirectToWhenAuthenticated: '/dashboard/quizes',
    redirectToWhenUnauthenticated: '/login',
  },
  endpoints: {
    users: {
      getMany: '/users',
    },
    usersQuestionAnswers: {
      getQuizzesPontuation: '/users/questions/answers/amount/',
    },
    places: {
      getMany: '/places',
      getManyWithParams: '/places?s={searchParams}',
    },
    odysseys: {
      getMany: '/odysseys',
    },
    quizzes: {
      getMany: '/quizes',
    },
    learnStyles: {
      getMany: '/learn-styles',
    },
    missions: {
      getMany: '/missions',
    },
  },
};
