<main class="col w-100 p-0">
  <div class="close-icon-container mb-3">
    <i class="material-symbols-outlined close-icon" (click)="onClickCancel()">close</i>
  </div>

  <h5>Quiz Normal</h5>
  <p class="mb-5">
    Esse é um Quiz no qual há apenas uma questão e você tem 4 respostas para
    selecionar, no qual apenas uma dessas respostas é a correta.
  </p>

  <h5>Quiz de Roleta</h5>
  <p class="mb-5">
    Esse é um Quiz no qual você tem 8 questões que serão sorteadas aleatoriamente. Após sortear, você irá responder
    essa questão selecionando Sim ou Não.
  </p>

  <h5>Quiz de Cartas</h5>
  <p>
    Esse é um Quiz no qual há apenas uma questão e você tem algumas cartas no qual irá escolher no máximo 3,
    e após escolher, iremos dizer se foram as cartas corretas ou erradas.
  </p>

</main>
