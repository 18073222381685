//#region Imports

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

//#endregion

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent
    }
  ]
})
export class DropdownComponent<T> implements ControlValueAccessor {

  //#region Public Properties

  @Input()
  public selectedValue: T[] | T | any;

  @Input()
  public placeholder: string;

  @Input()
  public isWhiteBackgroundColor: boolean = false;

  @Input()
  public maxSelectedItems: number = 0;

  @Input()
  public moreText?: string = ' ';

  @Input()
  public noResultsFound?: string = 'Nenhum item';

  @Input()
  public searchOnKey?: string = null;

  @Input()
  public searchPlaceholder?: string = null;

  @Input()
  public displayKey: string;

  @Input()
  public data: Array<T> = [];

  @Input()
  public returnKey: string;

  @Input()
  public multiple: boolean = false;

  @Input()
  public search: boolean = false;

  @Output()
  public selectedValueChange: EventEmitter<T | T[] | any> = new EventEmitter<T | T[] | any>();



  public touched: boolean = false;

  public disabled: boolean = false;

  public value: T | any;

  public onChange = (selectedValue: T | T[] | any) => {};

  public onTouched = () => {};

  //#endregion

  //#region Public Methods

  public get isAtMaxLimit(): boolean {
    if (Array.isArray(this.selectedValue) && this.multiple)
      if (this.maxSelectedItems && this.selectedValue?.length >= this.maxSelectedItems)
        return true;

    return false;
  }

  public setSelectedValue(value: T | T[]): void {
    if (this.isAtMaxLimit)
      return;

    if (this.selectedValue?.length === 0)
      value = null;

    this.markAsTouched();

    if (this.disabled)
      return;

    this.value = value;

    if (this.returnKey && this.value !== null) {
      if (this.selectedValue[this.returnKey] !== undefined)
        this.value = this.selectedValue[this.returnKey];

      if (Array.isArray(this.selectedValue) && this.multiple) {
        this.value = this.selectedValue.map(selectedValue => {
          return selectedValue[this.returnKey];
        });
      }

      if (Array.isArray(this.selectedValue) && !this.multiple) {
        this.value = this.selectedValue[0];
        this.value = this.value[this.returnKey];
      }
    }

    this.onChange(this.value);
    this.selectedValue = value;
    this.selectedValueChange.emit(this.selectedValue);
  }

  public writeValue(value: T | T[] | any): void {
    this.value = value;

    if (!value && value !== 0)
      return;

    if (this.returnKey) {
      if (this.multiple) {
        this.selectedValue = this.data.filter(findValue => this.value.includes(findValue[this.returnKey]));
        return;
      }

      this.selectedValue = this.data.find(findValue => findValue[this.returnKey] === this.value);
      return;
    }

    this.selectedValue = this.value;
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  //#endregion

}
