import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EnumToArrayPipeModule } from 'app/pipes/enum-to-array.pipe';
import { DropdownModule } from '../dropdown/dropdown.module';
import { DialogChangeUserPasswordComponent } from './dialog-change-user-password.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    EnumToArrayPipeModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    MatIconModule,
    MatInputModule,
  ],
  declarations: [
    DialogChangeUserPasswordComponent,
  ],
  entryComponents: [
    DialogChangeUserPasswordComponent,
  ],
})

export class DialogChangeUserPasswordModule {}
