//#region Imports

import { RouteInfo } from '../models/interfaces/route-info';

//#endregion

export class Keys {

  /**
   * @docs Ícones: https://material.io/resources/icons
   */
  public static readonly ROUTES: RouteInfo[] = [
    { path: '/dashboard/quizes', title: 'Quizzes', icon: 'library_books', class: '', roles: ['admin'] },
    { path: '/dashboard/missions', title: 'Missões', icon: 'map', class: '', roles: ['admin'] },
    { path: '/dashboard/learn-styles', title: 'Estilos de aprendizado', icon: 'lightbulb_outline', class: '', roles: ['admin'] },
    { path: '/dashboard/odysseys', title: 'Odisseias', icon: 'moving', class: '', roles: ['admin'] },
    { path: '/dashboard/campaigns', title: 'Campanhas', icon: 'calendar_today', class: '', roles: ['admin'] },
    { path: '/dashboard/places', title: 'Lugares', icon: 'map', class: '', roles: ['admin'] },
    { path: '/dashboard/users', title: 'Usuários', icon: 'people', class: '', roles: ['admin'] },
    { path: '/dashboard/skins', title: 'Skins', icon: 'face', class: '', roles: ['admin'] },
    { path: '/login', title: 'Sair', icon: 'logout', class: '', roles: ['admin'] },
  ];

  public static readonly TOKEN: string = 'KEY_TOKEN';

  public static readonly NOTIFICATION_TEMPLATE: string = '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
    '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
    '<i class="material-icons" data-notify="icon">notifications</i> ' +
    '<span data-notify="title">{1}</span> ' +
    '<span data-notify="message">{2}</span>' +
    '<div class="progress" data-notify="progressbar">' +
    '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
    '</div>' +
    '<a href="{3}" target="{4}" data-notify="url"></a>' +
    '</div>';

  public static USER_INFO: string = 'USER_INFO_KEY';

}
