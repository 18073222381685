//#region Imports

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RouteInfo } from '../../models/interfaces/route-info';
import { Keys } from '../../utils/keys';

//#endregion

//#region Components

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})

//#endregion

export class SidebarComponent implements OnInit {

  //#region Constructor

  constructor(
    private readonly router: Router,
  ) { }

  //#endregion

  //#region Public Properties

  public menuItems: RouteInfo[] = [];

  public version: string = environment.version;

  //#endregion

  //#region Public Functions

  public async ngOnInit(): Promise<void> {
    const user = localStorage.getItem(Keys.USER_INFO);

    if (!user) {
      localStorage.clear();

      await this.router.navigateByUrl('/login');

      return;
    }

    const userRoles = JSON.parse(user).permissions.split('|');
    this.menuItems = JSON.parse(JSON.stringify(Keys.ROUTES)).filter(route => userRoles.some(role => route.roles.includes(role)));
  }

  public async onClickToNavigate(path: string): Promise<void> {
    if (path !== '/login')
      return;

    localStorage.clear();
  }

  //#endregion

}
