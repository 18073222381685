//#region Imports

import { Parser } from 'json2csv';
import { Component, OnInit } from '@angular/core';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { DialogLoadingService } from '../../../components/dialog-loading/dialog.loading.service';
import { DialogYesnoService } from '../../../components/dialog-yesno/dialog.yesno.service';
import { BaseGetMany } from '../../../models/proxys/base/base-get-many.proxy';
import { OdysseyAnswerByOdysseyProxy } from '../../../models/proxys/odyssey-answer-by-odyssey.proxy';
import { OdysseyAnswerProxy } from '../../../models/proxys/odyssey-answer.proxy';
import { OdysseyProxy } from '../../../models/proxys/odyssey.proxy';
import { HttpAsyncService } from '../../../services/http-async/http-async.service';
import { PaginationHttpShared } from '../../../shared/pagination/pagination.http.shared';
import { getIdHashFromBatchedRequisition } from '../../../utils/functions';
import { JqueryHelper } from '../../../utils/jquery';

//#endregion

@Component({
  selector: 'app-list-odyssey-answers',
  templateUrl: './list-odyssey-answers.component.html',
  styleUrls: ['./list-odyssey-answers.component.scss'],
})
export class ListOdysseyAnswersComponent extends PaginationHttpShared<OdysseyProxy> implements OnInit {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    loading: DialogLoadingService,
    dialogYesNo: DialogYesnoService,
    http: HttpAsyncService,
  ) {
    super(loading, dialogYesNo, http,
      '/odysseys/',
      [
        'title',
        'totalAnswers',
        'answerOneCount',
        'answerTwoCount',
        'answerThreeCount',
        'answerFourCount',
        'answerFiveCount',
        'actions'],
      [
        'title',
        'totalAnswers',
        'answerOneCount',
        'answerOnePercentage',
        'answerTwoCount',
        'answerTwoPercentage',
        'answerThreeCount',
        'answerThreePercentage',
        'answerFourCount',
        'answerFourPercentage',
        'answerFiveCount',
        'answerFivePercentage'],
      async search => {
        const filter = {};

        return [
          filter,
          {
            $or: [
              { title: { $contL: search}, ...filter },
            ],
          },
        ];
      },
    );
  }

  //#endregion

  //#region Public Properties

  public odysseys: OdysseyProxy[] = [];
//#endregion

  //#region Lifecycle Methods

  public async ngOnInit(): Promise<void> {
    return super.ngOnInit();
  }

  //#endregion

  //#region Public Methods

  protected getFormattedDataToExcel(items: OdysseyProxy[]): any {
    return items.map((item) => ({
      ['Odisséia']: item.title,
      ['Total de Respostas']: item.totalAnswers,
      ['Resposta "1"']: item.answerOneCount,
      ['Porcentagem de respostas "1"']: item.answerOnePercentage + '%',
      ['Resposta "2"']: item.answerTwoCount,
      ['Porcentagem de respostas "2"']: item.answerTwoPercentage + '%',
      ['Resposta "3"']: item.answerThreeCount,
      ['Porcentagem de respostas "3"']: item.answerThreePercentage + '%',
      ['Resposta "4"']: item.answerFourCount,
      ['Porcentagem de respostas "4"']: item.answerFourPercentage + '%',
      ['Resposta "5"']: item.answerFiveCount,
      ['Porcentagem de respostas "5"']: item.answerFivePercentage + '%',
    }));
  }

  //#endregion

}
