import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  public transform<T>(data: any): string[] {
    const keys = Object.keys(data);
    return keys.slice(0, keys.length / 2);
  }
}

@NgModule({
  declarations: [
    EnumToArrayPipe,
  ],
  exports: [
    EnumToArrayPipe,
  ],
})
export class EnumToArrayPipeModule {}
