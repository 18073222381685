//#region Imports

import { AfterViewInit, ElementRef, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QueryJoin, QueryJoinArr, QuerySort, RequestQueryBuilder, SCondition } from '@nestjsx/crud-request';

import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as xlsx from 'xlsx';

import { DialogLoadingService } from '../../components/dialog-loading/dialog.loading.service';
import { DialogYesnoService } from '../../components/dialog-yesno/dialog.yesno.service';
import { BaseEntity } from '../../models/base/base-entity';
import { CrudRequestResponseProxy } from '../../models/proxys/base/crud-request-response.proxy';
import { AsyncResult, HttpAsyncService } from '../../services/http-async/http-async.service';
import { getCrudErrors } from '../../utils/functions';
import { JqueryHelper } from '../../utils/jquery';

//#endregion

/**
 * A classe que representa o conteúdo básico para uma página que irá conter páginação
 */
@Directive()
export class PaginationHttpShared<TProxy extends BaseEntity> implements OnInit, AfterViewInit, OnDestroy {

  //#region Constructor

  /**
   * Construtor padrão
   *
   * @param loading O elemento que exibe a modal de loading
   * @param dialogYesNo O serviço que exibe a modal de sim e não
   * @param http O serviço http que realiza as requisições
   * @param route A rota para qual essa classe irá buscar os valores
   * @param displayedColumns O nome das colunas que serão exibidas
   * @param entityColumns As colunas da entidade que poderão ser usadas no filtro
   * @param searchConditions Os parametros para pesquisa e filtro
   * @param sortBy Diz qual é o tipo de sort que deve ser feito
   * @param joins Diz as ligações que esse entidade deve ter
   * @param customSearchParams
   */
  constructor(
    protected loading: DialogLoadingService,
    protected dialogYesNo: DialogYesnoService,
    protected http: HttpAsyncService,
    protected route: string,
    public displayedColumns: string[],
    protected entityColumns: string[],
    protected searchConditions?: (search: string) => Promise<SCondition | [SCondition, SCondition]>,
    protected sortBy: QuerySort = {
      field: 'createdAt',
      order: 'ASC',
    },
    protected joins: QueryJoin | QueryJoinArr | Array<QueryJoin | QueryJoinArr> = [],
    protected customSearchParams: (search: string) => Promise<Object> = async search => new Object(''),
  ) { }

  //#endregion

  //#region View Childs

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  @ViewChild('input', { static: true })
  public searchInput: ElementRef;

  //#endregion

  //#region Public Properties

  public isLoadingResults = true;

  public dataSource: MatTableDataSource<TProxy>;

  public pageEvent: Partial<PageEvent> = {
    pageIndex: 0,
    pageSize: 15,
  };

  public pageSizeDefault: number = 15;

  public defaultSortOrder: { field: string; order: 'ASC' | 'DESC' };

  public includeOnlyActives = true;

  //#endregion

  //#region protected properties

  protected shouldNotSearchOnTextInput: boolean = false;

  //#endregion

  //#region Private Properties

  private searchInputSubscription: Subscription;

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.dataSource = new MatTableDataSource<TProxy>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const { error, success } = await this.getValues<TProxy>(this.route, 0, this.pageSizeDefault);

    if (success) {
      this.dataSource.connect().next(success);
    } else {
      JqueryHelper.notify('add_alert', error && error.error && error.error.message || 'Ocorreu um erro ao buscar os items, por favor, tente novamente!', 'danger');
    }

    this.isLoadingResults = false;
  }

  public ngAfterViewInit(): void {
    if (!this.searchInput || !this.searchInput.nativeElement)
      return;

    if (this.shouldNotSearchOnTextInput)
      return;

    this.searchInputSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.pageEvent.pageIndex = 0;

          this.onPageChange(this.pageEvent);
        })
      ).subscribe();
  }

  public ngOnDestroy(): void {
    this.searchInputSubscription && this.searchInputSubscription.unsubscribe();
  }

  //#endregion

  //#region Public Methods

  public async onChangeSort(sortedHeader: Sort): Promise<void> {
    if (sortedHeader.direction !== '') {
      this.sortBy = {
        field: sortedHeader.active,
        order: sortedHeader.direction.toUpperCase() as 'ASC' | 'DESC',
      };
    } else
      this.sortBy = this.defaultSortOrder;

    await this.reloadOnFirstPage();
  }

  public async reloadOnFirstPage(): Promise<void> {
    this.pageEvent.pageIndex = 0;

    await this.onPageChange(this.pageEvent);
  }

  public async onPageChange(event: Partial<PageEvent>): Promise<void> {
    this.isLoadingResults = true;

    this.pageEvent = event;

    const value = this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value || '';
    const searchValue = this.isString(value) && value.trim().toLocaleLowerCase() || '';

    const { error, success } = await this.getValues<TProxy>(this.route, this.pageEvent.pageIndex || 0, this.pageEvent.pageSize || this.pageSizeDefault, searchValue);

    this.isLoadingResults = false;

    if (error)
      return JqueryHelper.error(error.message);

    this.dataSource.connect().next(success);
  }

  public async onClickToRemoveItem(entity: BaseEntity, deleteMessage?: string): Promise<void> {
    this.dialogYesNo.openDialog({
      title: '',
      message: deleteMessage || 'Deseja mesmo excluir esse elemento?',
      okayText: 'Excluir',
      cancelText: 'Manter',
      shouldShowDeleteIcon: true,

      onClickOkayButton: async () => {
        this.loading.open();

        const { error } = await this.http.delete(`${this.route}/${entity.id}`);

        this.loading.close();

        if (error)
          return JqueryHelper.error(getCrudErrors(error)[0]);

        JqueryHelper.success('A operação foi executada com sucesso!');

        await this.onPageChange(this.pageEvent);
      },
    });
  }

  public async onClickToToggleIsActive(entity: BaseEntity): Promise<void> {
    this.loading.open();

    const url = `${this.route}/${entity.id}/${(entity.isActive ? 'disable' : 'enable')}`;
    const { error } = await this.http.put<unknown>(url, {});

    this.loading.close();

    if (error)
      return JqueryHelper.error(getCrudErrors(error)[0]);

    JqueryHelper.success('A operação foi executada com sucesso!');

    await this.onPageChange(this.pageEvent);
  }

  public async onClickExport(exportName: string): Promise<void> {
    this.isLoadingResults = true;

    const value = this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value || '';
    const searchValue = this.isString(value) && value.trim().toLocaleLowerCase() || '';
    const { error, success } = await this.getAllData(0, 200, this.route, searchValue);
    this.isLoadingResults = false;

    if (error || !success || !success.length)
      return;

    const formattedDataToExcel = this.getFormattedDataToExcel(success);
    const sheet = xlsx.utils.json_to_sheet(formattedDataToExcel);
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, sheet, exportName);
    xlsx.writeFile(workbook, `${ exportName } v${ +new Date() }.xlsx`);
  }

  protected getFormattedDataToExcel(success: TProxy[]): any {
    return success;
  }

  //#endregion

  //#region Protected Methods

  protected async getAllData(page: number = 0, limit = 200, url: string, search?: string): Promise<AsyncResult<TProxy[]>> {
    let query = new RequestQueryBuilder()
      .select(this.entityColumns.map(key => String(key)))
      .setPage(page + 1)
      .setLimit(limit)
      .setJoin(this.joins)
      .setOffset(0)
      .sortBy(this.sortBy)
      .search({});

    const searchQuery = this.searchConditions && await this.searchConditions(search);
    const customSearchQuery = await this.customSearchParams && await this.customSearchParams(search);

    if (searchQuery) {
      if (Array.isArray(searchQuery)) {
        if (!search)
          query = query.search(searchQuery[0]);
        else
          query = query.search(searchQuery[1]);
      } else {
        query = query.search(searchQuery);
      }
    }

    const customQueryParams = this.buildCustomParamStringByObject(customSearchQuery);

    const queryParams = query.query(true) + customQueryParams;

    const { success, error } = await this.http.get<CrudRequestResponseProxy<TProxy>>(`${ url }${ url.includes('?') ? '&' : '?' }${ queryParams }`);

    if (error)
      return { error };

    const sessionQuestions = Array.isArray(success) ? success : success?.data;

    if (sessionQuestions.length !== limit)
      return { success: sessionQuestions };

    const { error: errorOnGetMore, success: moreSessionQuestions } = await this.getAllData(page + 1, 200, this.route);

    if (errorOnGetMore)
      return { error: errorOnGetMore };

    return {
      success: [...sessionQuestions, ...(moreSessionQuestions || [])],
    };
  }

  protected async applySearchParamsToQuery(query: RequestQueryBuilder, search?: string): Promise<RequestQueryBuilder> {
    const searchQuery = this.searchConditions && await this.searchConditions(search || '');

    if (searchQuery) {
      if (Array.isArray(searchQuery)) {
        if (!search) {
          query = query.search({
            ...this.includeOnlyActives && { isActive: this.includeOnlyActives },
            ...searchQuery[0],
          });
        } else {
          query = query.search({
            ...this.includeOnlyActives && { isActive: this.includeOnlyActives },
            ...searchQuery[1],
          });
        }
      } else {
        query = query.search({
          ...this.includeOnlyActives && { isActive: this.includeOnlyActives },
          ...searchQuery,
        });
      }
    }

    return query;
  }

  protected async getValues<T>(url: string, page: number, limit: number, search?: string): Promise<AsyncResult<T[]>> {
    let query = new RequestQueryBuilder()
      .select(this.entityColumns)
      .setPage(page + 1)
      .setLimit(limit)
      .setJoin(this.joins)
      .setOffset(0)
      .sortBy(this.sortBy)
      .search({});

    const searchQuery = this.searchConditions && await this.searchConditions(search);
    const customSearchQuery = await this.customSearchParams && await this.customSearchParams(search);

    if (searchQuery) {
      if (Array.isArray(searchQuery)) {
        if (!search)
          query = query.search(searchQuery[0]);
        else
          query = query.search(searchQuery[1]);
      } else {
        query = query.search(searchQuery);
      }
    }

    const customQueryParams = this.buildCustomParamStringByObject(customSearchQuery);

    const queryParams = query.query(true) + customQueryParams;

    const { success, error } = await this.http.get<CrudRequestResponseProxy<T>>(`${url}${url.includes('?') ? '&' : '?'}${queryParams}`);

    if (error)
      return { error };

    this.dataSource.paginator.pageIndex = success.page - 1;
    this.dataSource.paginator.length = success.total;
    this.dataSource.paginator.pageSize = success.count < this.pageSizeDefault ? this.pageSizeDefault : success.count;

    this.pageEvent = {
      length: success.total,
      pageSize: success.count < this.pageSizeDefault ? this.pageSizeDefault : success.count,
      pageIndex: success.page - 1,
    };

    return { success: success.data };
  }

  protected buildCustomParamStringByObject(customParam: Object): string {
    const str = [];

    for (const properties in customParam) {
      if (customParam.hasOwnProperty(properties))
        str.push(encodeURIComponent(properties) + '=' + encodeURIComponent(customParam[properties]));
    }

    return str.length > 0 ? '&' + str.join('&') : '';
  }

  protected isString(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object String]';
  }

  //#endregion

}
