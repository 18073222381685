<main class="col">
  <div class="close-icon-container">
    <i class="material-symbols-outlined close-icon" (click)="onClickCancel()">close</i>
  </div>
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content class="mat-typography">
    <p class="text-wrap text-justify text-message">{{ data.message }}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-between">
    <button class="button-primary btn-success-modal mb-4" (click)="onClickCancel()" [mat-dialog-close]="true" cdkFocusInitial>{{ data.cancelText || 'Não' }}</button>
    <button class="button-confirm-remove mb-4 delete" (click)="onClickOkay()">
      <ng-container *ngIf="data.shouldShowDeleteIcon">
        <i class="material-symbols-outlined">delete</i>
      </ng-container>
      {{ data.okayText || 'Sim' }}
    </button>
  </mat-dialog-actions>
</main>
