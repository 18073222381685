import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogQuizInfoComponent } from './dialog-quiz-info.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
  ],
  declarations: [
    DialogQuizInfoComponent,
  ],
  entryComponents: [
    DialogQuizInfoComponent,
  ],
})

export class DialogQuizInfoModule {}
