//#region Imports

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

//#endregion

export interface DialogYesnoContent {
  title: string;

  message: string;

  okayText?: string;

  cancelText?: string;

  onClickOkayButton?: Function;

  onClickCancelButton?: Function;

  shouldShowDeleteIcon?: boolean;
}

@Component({
  selector: 'app-dialog-yesno',
  templateUrl: './dialog.yesno.component.html',
  styleUrls: ['./dialog.yesno.component.scss'],
})
export class DialogYesnoComponent {

  //#region Constructor

  constructor(
    public dialogRef: MatDialogRef<DialogYesnoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogYesnoContent,
  ) { }

  //#endregion

  //#region Public Methods

  public onClickOkay(): void {
    this.dialogRef.close();

    if (typeof this.data.onClickOkayButton === 'function')
      this.data.onClickOkayButton();
  }

  public onClickCancel(): void {
    this.dialogRef.close();

    if (typeof this.data.onClickCancelButton === 'function')
      this.data.onClickCancelButton();
  }

  //#endregion

}
