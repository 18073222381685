import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RequestPasswordRecovery } from '../../models/interfaces/request-password-recovery';
import { PasswordResetCodeProxy } from '../../models/proxys/password-code-reset.proxy';
import { HttpAsyncService } from '../../services/http-async/http-async.service';
import CustomValidators from '../../utils/custom-validators';
import { getCrudErrors } from '../../utils/functions';
import { JqueryHelper } from '../../utils/jquery';

@Component({
  selector: 'app-dialog-reset-password',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.scss'],
})
export class DialogResetPasswordComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<DialogResetPasswordComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly http: HttpAsyncService,
    private readonly router: Router
  ) {}

  public errorMatcher = new ErrorStateMatcher();

  public formGroup: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.minLength(6)]],
      emailConfirmation: ['', [Validators.required]]
  },
    {
      validators: [CustomValidators.match('email', 'emailConfirmation')]
    }
  );

  public onClose(): void {
    this.dialogRef.close();
  }

  public async save(): Promise<PasswordResetCodeProxy> {
    const formObject = this.formGroup.getRawValue();
    delete formObject.passwordConfirmation;

    const payload: RequestPasswordRecovery = formObject;
    const { success, error } = await this.http.post<PasswordResetCodeProxy>(`/password-reset-codes`, payload);

    if (error) {
      JqueryHelper.error(getCrudErrors(error)[0]);
      return null;
    }

    JqueryHelper.success(`Email enviado com sucesso!`);

    await this.router.navigateByUrl('/login');

    return success;
  }

  public async onSubmit(): Promise<void> {
    if (await this.save())
     this.onClose();
  }

  public onClickCancel() {
    this.onClose();
  }
}
