//#region Imports

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

//#endregion

@Component({
  selector: 'app-dialog-quiz-info',
  templateUrl: './dialog-quiz-info.component.html',
  styleUrls: ['./dialog-quiz-info.component.scss'],
})
export class DialogQuizInfoComponent {

  //#region Constructor

  constructor(
    public dialogRef: MatDialogRef<DialogQuizInfoComponent>,
  ) { }

  //#endregion

  //#region Public Methods

  public onClickCancel(): void {
    this.dialogRef.close();
  }

  //#endregion

}
