import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordInterface } from '../../models/interfaces/change-password.interface';
import { UserProxy } from '../../models/proxys/user.proxy';
import { HttpAsyncService } from '../../services/http-async/http-async.service';
import CustomValidators from '../../utils/custom-validators';
import { getCrudErrors } from '../../utils/functions';
import { JqueryHelper } from '../../utils/jquery';
import { Keys } from '../../utils/keys';

@Component({
  selector: 'app-dialog-change-user-password',
  templateUrl: './dialog-change-user-password.component.html',
  styleUrls: ['./dialog-change-user-password.component.scss'],
})
export class DialogChangeUserPasswordComponent implements OnInit {

  constructor(
    private readonly dialogRef: MatDialogRef<DialogChangeUserPasswordComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly http: HttpAsyncService,
    private cdr: ChangeDetectorRef
  ) {
    this.loggedUser = JSON.parse(localStorage.getItem(Keys.USER_INFO));
  }

  private loggedUser: UserProxy;

  public errorMatcher = new ErrorStateMatcher();

  public hidePassword = true;

  public hidePasswordConfirmation = true;

  public formGroup: FormGroup = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    passwordConfirmation: ['', [Validators.required]]
  },
    {
      validators: [CustomValidators.match('password', 'passwordConfirmation')]
    }
  );

  public ngOnInit(): void {
    if (!this.loggedUser.needChangePassword) {
      this.onClose();
      this.cdr.detectChanges();
      return;
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public async save(): Promise<UserProxy> {
    const formObject = this.formGroup.getRawValue();
    delete formObject.passwordConfirmation;

    const payload: ChangePasswordInterface = formObject;
    const { success, error } = await this.http.put<UserProxy>(`/users/${ this.loggedUser.id }`, payload);

    if (error) {
      JqueryHelper.error(getCrudErrors(error)[0]);
      return null;
    }

    JqueryHelper.success(`Senha atualizada com sucesso!`);

    return success;
  }

  public async onSubmit(): Promise<void> {
    if (await this.save())
    this.onClose();
  }
}
