<div class="main-content">
  <div class="container-fluid card">
    <section class="d-flex flex-wrap card-header card-header-info d-flex justify-content-between mx-0">
      <div class="col-12 col-md-7">
        <h4 class="card-title">Respostas de Odisséias</h4>
        <p class="card-category">A seguir, você pode ver uma análise das respostas das odisséias.</p>
      </div>

      <div class="col-12 col-md-5 mt-3 mt-md-0 d-flex align-items-center justify-content-around justify-content-md-end">
        <button class="disabled-secondary-section-button mr-0 mr-md-4" type="button" routerLink="/dashboard/odysseys">
          ODISSÉIAS
        </button>
        <button class="enabled-secondary-section-button">
          RESPOSTAS
        </button>
      </div>
    </section>
    <div>
      <div class="w-100 d-flex flex-wrap my-5">
        <div class="col-12 col-md-6 p-0 px-3 px-md-5" style="flex: 1;">
          <div class="d-flex">
            <input placeholder="Pesquisar respostas" #input class="search-input">
          </div>
        </div>

        <div class="col-12 col-md-6 p-0 px-3 px-md-5 d-flex justify-content-end">
          <a class="btn button-primary ml-auto mr-4" #export (click)="onClickExport('odisseias_respostas')">Gerar
            Planilha
          </a>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="force-border-radius force-box-shadow loading-shade-container mat-elevation-z8">
        <div class="loading-shade" *ngIf="isLoadingResults">
          <mat-spinner></mat-spinner>
        </div>

        <table class="force-new-table" mat-table matSort [dataSource]="dataSource" (matSortChange)="onChangeSort($event)">


          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Odisséias</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ element.title }}</td>
          </ng-container>

          <ng-container matColumnDef="totalAnswers">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Nº Total de Respostas</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ element.totalAnswers }}</td>
          </ng-container>

          <ng-container matColumnDef="answerOneCount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Resposta "1"</th>
            <td mat-cell *matCellDef="let element; let i = index">{{  element.answerOneCount + ' (' + (element.answerOnePercentage.toFixed(0)) }}
              %)
            </td>
          </ng-container>


          <ng-container matColumnDef="answerTwoCount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Resposta "2"</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ element.answerTwoCount + ' (' + element.answerTwoPercentage.toFixed(0) }}
              %)
            </td>
          </ng-container>

          <ng-container matColumnDef="answerThreeCount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Resposta "3"</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ element.answerThreeCount + ' (' + element.answerThreePercentage.toFixed(0) }}
              %)
            </td>
          </ng-container>

          <ng-container matColumnDef="answerFourCount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Resposta "4"</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ element.answerFourCount + ' (' + element.answerFourPercentage.toFixed(0) }}
              %)
            </td>
          </ng-container>

          <ng-container matColumnDef="answerFiveCount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Resposta "5"</th>
            <td mat-cell *matCellDef="let element; let i = index">{{  element.answerFiveCount + ' (' + element.answerFivePercentage.toFixed(0)}}
              %)
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Ações</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <button class="button-search outline-primary mt-md-1" [routerLink]="'/dashboard/list-odysseys-answers/' + element.id" >
                <span class="list-filter-span" >VER RESPOSTAS</span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[30]" [hidePageSize]="true" (page)="onPageChange($event)"
                       showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>

