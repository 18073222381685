<div class="logo">
  <img class="justify-content-center mw-100 px-3" src="assets/imgs/logo.png" alt="Logo">
</div>
<div class="sidebar-wrapper py-0 d-flex">
  <ul class="nav d-flex align-items-start flex-grow-1 flex-shrink-1 flex-column">
    <li *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item w-100" routerLinkActive="active">
      <a [routerLink]="[menuItem.path]" (click)="onClickToNavigate(menuItem.path)" class="nav-link">
        <i class="material-icons">{{ menuItem.icon }}</i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>

    <div class="d-flex flex-1 justify-content-end flex-column align-content-center w-100 py-4">
      <img class="liga-logo" src="assets/imgs/liga_logo.svg" alt="Liga">
      <p class="version">v{{ version }}</p>
    </div>

  </ul>
</div>
