<ngx-select-dropdown
    [(ngModel)]="selectedValue"
    (ngModelChange)="setSelectedValue($event)"
    [options]="data"
    [class.select-disable-value]="isAtMaxLimit"
    [multiple]="multiple"
    [class.select-dropdown-white]="isWhiteBackgroundColor"
    [config]="{
      placeholder: placeholder,
      displayKey: displayKey,
      noResultsFound: noResultsFound,
      moreText: moreText,
      searchPlaceholder: searchPlaceholder,
      searchOnKey: searchOnKey,
      search: search
    }"
></ngx-select-dropdown>
