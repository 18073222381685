import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import localeFr from '@angular/common/locales/pt-PT';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { DialogChangeUserPasswordModule } from './components/dialog-change-user-password/dialog-change-user-password.module';
import { DialogQuizInfoModule } from './components/dialog-quiz-info/dialog-quiz-info.module';
import { DialogResetPasswordModule } from './components/dialog-reset-password/dialog-reset-password.module';
import { httpAsyncFactory } from './factories/http-async/http-async.factory';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpAsyncModule } from './modules/http-async/http-async.module';
import { HttpAsyncService } from './services/http-async/http-async.service';
import { Interceptor } from './utils/interceptor';
import { ListOdysseyAnswersComponent } from './pages/odysseys/list-odyssey-answers/list-odyssey-answers.component';

registerLocaleData(localeFr, 'pt-PT');

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    DialogQuizInfoModule,
    LeafletModule,
    NgxQRCodeModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DialogChangeUserPasswordModule,
    DialogResetPasswordModule,
    HttpAsyncModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    ListOdysseyAnswersComponent,
  ],
  providers: [
    { provide: HttpAsyncService, useFactory: httpAsyncFactory, deps: [HttpClient] },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
  bootstrap: [
    AppComponent,
  ]
})

export class AppModule {
}
