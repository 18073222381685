import { JwtHelperService } from '@auth0/angular-jwt';
import { RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import decode from 'jwt-decode';
import { HttpAsyncService } from '../services/http-async/http-async.service';

/**
 * Método que pausa a execução por uma certa quantidade de tempo
 *
 * @param ms A quantidade de tempo em millisegundos
 */
export async function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Método que retorna a largura da tela
 */
export function getScreenWidth(): number {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

/**
 * Método que retorna uma lista de erros
 *
 * @param error
 */
export function getCrudErrors({ status, error }: any): string[] {
  if (status >= 500 && status <= 599)
    return ['Ocorreu um erro interno, por favor, tente novamente.'];

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return ['A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.'];

    return [error.message || 'Ocorreu um erro inesperado, por favor, contate os administradores se o erro persistir.'];
  }

  if (error.message.every(message => typeof message === 'string'))
    return error.message;

  // @ts-ignore
  return error.message.map(({ constraints }) => constraints && Object.values(constraints) || [])
    .reduce((acc, actual) => [...acc, ...actual] as string[]);
}

/**
 * Método que carrega uma imagem base64
 *
 * @param file A referencia da imagem
 * @param onLoad O callback ao carregar a imagem
 */
export function processBase64Image(file: any, onLoad: (base64: string) => void): void {
  if (!file)
    return;

  const reader = new FileReader();

  reader.onloadend = () => {
    if (typeof reader.result !== 'string')
      return;

    onLoad(reader.result);
  };

  reader.readAsDataURL(file);
}

export function sortByProperty<T>(property: keyof T): (a: T, B: T) => number {
  return (a, b) => {
    if (a[property] > b[property])
      return 1;

    if (a[property] < b[property])
      return -1;

    return 0;
  };
}

/**
 * Método que verifica se o usuário tem permissão de administrador
 *
 * @param token As informações do token de autenticação
 */
export function isAdmin(token: string): boolean {
  const jwt = new JwtHelperService();

  if (!token || jwt.isTokenExpired(token))
    return false;

  const tokenPayload = decode(token);

  if (typeof tokenPayload.roles !== 'string')
    return false;

  return hasAdminRoles(tokenPayload.roles);
}

/**
 * Método que diz se o usuário possui permissões de administrador
 *
 * @param roles As permissões do usuário
 */
export function hasAdminRoles(roles: string): boolean {
  const rolesArray = typeof roles === 'string' && roles.split('|') || '';

  return rolesArray.includes('admin');
}

/**
 * Método para pegar grande quantidade de dados realizando requisições sequenciais
 * @param http O serviço http
 * @param path Rota para pegar as entidades
 * @param limit Limite de entidades por requisição
 * @param joins Relações pertinentes
 * @param searches Parâmentros de busca
 */
export async function getBatchedRequisition<T>(http: HttpAsyncService, path: string, limit: number, joins?: string[], searches?: SFields): Promise<T[]> {

  const result: T[] = [];
  let i = 1;
  let batchSize: number;
  const maxTries = 3;

  do {

    let requestQuery = RequestQueryBuilder.create().setLimit(limit).setPage(i);

    if (joins)
      for (const join of joins) {
        requestQuery = requestQuery.setJoin({ field: join });
      }

    if (searches)
      requestQuery = requestQuery.search(searches);

    const query = requestQuery.query(true);

    let batchRequisition;

    for (let y = 0; y < maxTries; y++) {
      batchRequisition = await http.get<any>(`${ path }?${ query }`);
      if (batchRequisition.success) {
        batchSize = batchRequisition.success.data.length;
        result.push(...batchRequisition.success.data);
        break;
      }
    }

    i++;

  } while (batchSize >= limit);

  return result;

}

/**
 * Método para pegar grande quantidade de dados realizando requisições sequenciais e gerando um hashtable com identificador sendo o id da entidade
 * @param http O serviço http
 * @param path Rota para pegar as entidades
 * @param limit Limite de entidades por requisição
 * @param joins Relações pertinentes
 * @param searches Parâmentros de busca
 */
export async function getIdHashFromBatchedRequisition(http: HttpAsyncService, path: string, limit: number, joins?: string[], searches?: SFields): Promise<{}> {

  const success = await getBatchedRequisition<any>(http, path, limit, joins, searches);
  const hashtable = {};
  for (let i = 0; i < success.length; i++) {
    const itemToAdd = success[i];
    if (!hashtable[itemToAdd.id])
      hashtable[itemToAdd.id] = itemToAdd;
  }

  return hashtable;

}

export function isEmpty<T>(value: T | undefined | null | '' | number): value is T {
  return value === null || value === undefined || value === '' || value === 0;
}

export function validateNumberInput(value: string): string {
  return value.replace(/[^0-9]/g, '');
}
