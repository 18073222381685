<main class="check w-100 d-flex align-items-center justify-content-center">
<form class="form"  [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="close-icon-container mb-3">
    <i class="material-symbols-outlined close-icon" (click)="onClickCancel()">close</i>
  </div>
  <h2 class="text-center">Digite seu email</h2>

  <input
      matInput
      placeholder="Email"
      formControlName="email"
      [errorStateMatcher]="errorMatcher"
      (keydown.enter)="emailConfirmation.focus()"
  >

  <div>
  <input
      #emailConfirmation
      matInput
      placeholder="Confirmação do email"
      formControlName="emailConfirmation"
      [errorStateMatcher]="errorMatcher"
  >
  </div>

  <button [class.disable]="formGroup.invalid" class="btn-redefine" mat-stroked-button color="primary">
    Enviar e-mail de recuperação
  </button>
</form>
</main>
