<main class="check w-100 d-flex align-items-center justify-content-center">
<form class="form"  [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 class="text-center">Redefina sua senha!</h2>

  <label class="required-symbol">Senha</label>
  <input
      matInput
      placeholder="Senha"
      formControlName="password"
      [type]="hidePassword ? 'password' : 'text'"
      [errorStateMatcher]="errorMatcher"
      (keydown.enter)="passwordConfirmation.focus()"
  >
  <button mat-icon-button matSuffix type="button" (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
  </button>

  <div>

    <label class="required-symbol">Confirmar senha</label>
    <input
      #passwordConfirmation
      matInput
      placeholder="Confirmação da senha"
      formControlName="passwordConfirmation"
      [type]="hidePasswordConfirmation ? 'password' : 'text'"
      [errorStateMatcher]="errorMatcher"
      (keydown.enter)="onSubmit()"
  >
    <button mat-icon-button matSuffix type="button" (click)="hidePasswordConfirmation = !hidePasswordConfirmation" [attr.aria-label]="'Hide password confirmation'" [attr.aria-pressed]="hidePasswordConfirmation">
      <mat-icon>{{ hidePasswordConfirmation ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </div>

  <button [class.disable]="formGroup.invalid" class="btn-redefine" mat-stroked-button color="primary">
    Redefinir
  </button>
</form>
</main>
